import '../../assets/plugins/react-charts.css';
import './DashboardPB.css';

import React, { Component } from 'react';

import ApiPowerBI from '../../api/ApiPowerBI';
import Cookies from 'universal-cookie';
import PowerBiComponent from './PowerBiComponent';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

class DashboardManagementControl extends Component {
  constructor(props) {
    super(props)
    this.state = {
      companyId: 1,
      isNewVersion: false,
      loading: true,
    }
  }

  componentDidMount() {
    this.setState({
      companyId: localStorage.getItem('itlg_default_company_id'),
    });
    ApiPowerBI.callReport("CGESTION", this.onSuccess, this.onError);
  }

  onSuccess = (data) => {
    this.setState({ type: "CGESTION", reportToken: data.reportToken, reportId: data.reportId, isNewVersion: true, error: null, loading: false });
  }

  onError = () => {
    this.setState({ isNewVersion: false, loading: false });
  }

  getLinkCompany11() {
    const companyPartnerShipLinks = {
      1: 'https://app.powerbi.com/reportEmbed?reportId=a8266459-c7fd-44e9-b002-38535979ca07&autoAuth=true&ctid=ce6a7f5c-dd9a-4115-ae3a-a05a33587ae3',
      8: 'https://app.powerbi.com/reportEmbed?reportId=a8266459-c7fd-44e9-b002-38535979ca07&autoAuth=true&ctid=ce6a7f5c-dd9a-4115-ae3a-a05a33587ae3',
      13: 'https://app.powerbi.com/view?r=eyJrIjoiYzc0NjYyMjktMDE2NC00OWZhLThiOWMtNjBiM2NiYjgzMWEyIiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9',
      14: 'https://app.powerbi.com/reportEmbed?reportId=a8266459-c7fd-44e9-b002-38535979ca07&autoAuth=true&ctid=ce6a7f5c-dd9a-4115-ae3a-a05a33587ae3',
      15: 'https://app.powerbi.com/reportEmbed?reportId=a8266459-c7fd-44e9-b002-38535979ca07&autoAuth=true&ctid=ce6a7f5c-dd9a-4115-ae3a-a05a33587ae3',
      16: 'https://app.powerbi.com/view?r=eyJrIjoiNmMzZGRkNzktZGI2Ny00MjNjLWIxNzEtOTYyNmU1NTM0Zjk5IiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9&pageName=ReportSection4e0642729b13a637881d',
      17: 'https://app.powerbi.com/view?r=eyJrIjoiYWVlZTJiZGYtYzZkMy00NTk4LWE1NDMtNGVlYWQwNWIzZmRkIiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9',
      18: 'https://app.powerbi.com/reportEmbed?reportId=a8266459-c7fd-44e9-b002-38535979ca07&autoAuth=true&ctid=ce6a7f5c-dd9a-4115-ae3a-a05a33587ae3',
      19: 'https://app.powerbi.com/view?r=eyJrIjoiNzhkMmViY2QtMDc2NC00NGQ3LWI2M2QtZTI1ODZlMjI0ZDU1IiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9',
      20: 'https://app.powerbi.com/view?r=eyJrIjoiOWMyZWQ2MDktNmU2Yy00OGQ3LWI2MjYtMDJlNTE2MDNiOTZiIiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9&pageName=ReportSection4e0642729b13a637881d',
      23: 'https://app.powerbi.com/view?r=eyJrIjoiYzJlY2YyNzktOGFhNC00OTU1LWFiNmEtY2QwMGM0MmNhNDQ1IiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9',
      24: 'https://app.powerbi.com/view?r=eyJrIjoiYmE5NmI1YTgtN2M3OS00MDYzLWEzZTctZTQ0MmZmY2M0ZTAwIiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9',
      25: 'https://app.powerbi.com/view?r=eyJrIjoiZWViMjk4MjgtYjhiOS00NWQxLTkxMTQtNjM2MjFiM2Q0YjYyIiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9',
    };

    const companyPartnerShip = parseInt(localStorage.getItem('company_partnership_id'));
    return companyPartnerShipLinks[companyPartnerShip] || '';
  }

  getDashboardLink() {
    const companyId = parseInt(localStorage.getItem('itlg_default_company_id'))
    const companyDashboardLinks = {
      1: 'https://app.powerbi.com/reportEmbed?reportId=4e994bfa-c492-4126-8c66-494ba426ead6&autoAuth=true&ctid=ce6a7f5c-dd9a-4115-ae3a-a05a33587ae3',
      4: 'https://app.powerbi.com/view?r=eyJrIjoiNTY5ZDdmMWYtYjA2Yy00NGUyLTk5ODItNmM4YzgxYWY5OTA3IiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9',
      5: 'https://app.powerbi.com/reportEmbed?reportId=e103dd2d-d9a8-47aa-81cb-600071ce651a&autoAuth=true&ctid=ce6a7f5c-dd9a-4115-ae3a-a05a33587ae3',
      7: 'https://app.powerbi.com/reportEmbed?reportId=b0cc8547-7f84-47b7-89c5-f344652389b5&autoAuth=true&ctid=ce6a7f5c-dd9a-4115-ae3a-a05a33587ae3&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXBhYXMtMS1zY3VzLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0LyJ9%22',
      8: 'https://app.powerbi.com/reportEmbed?reportId=4e994bfa-c492-4126-8c66-494ba426ead6&autoAuth=true&ctid=ce6a7f5c-dd9a-4115-ae3a-a05a33587ae3',
      10: 'https://app.powerbi.com/view?r=eyJrIjoiN2EzMGFmY2EtNGUzMS00ZjFlLThkYzEtNmFhMWY0NWM4MzMzIiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9',
      11: this.getLinkCompany11(),
      12: 'https://app.powerbi.com/reportEmbed?reportId=279ed19b-4d10-4477-b0a1-d3c77f03b9fe&autoAuth=true&ctid=ce6a7f5c-dd9a-4115-ae3a-a05a33587ae3&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXBhYXMtMS1zY3VzLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0LyJ9%22',
      13: 'https://app.powerbi.com/view?r=eyJrIjoiODUxNjU0MDQtYWM2Yy00Y2I1LThjMzgtNGM3YTkyY2RmYzU0IiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9&pageName=ReportSection4e0642729b13a637881d',
      14: 'https://app.powerbi.com/reportEmbed?reportId=4e994bfa-c492-4126-8c66-494ba426ead6&autoAuth=true&ctid=ce6a7f5c-dd9a-4115-ae3a-a05a33587ae3',
      15: 'https://app.powerbi.com/reportEmbed?reportId=4e994bfa-c492-4126-8c66-494ba426ead6&autoAuth=true&ctid=ce6a7f5c-dd9a-4115-ae3a-a05a33587ae3',
      16: 'https://app.powerbi.com/view?r=eyJrIjoiNmMzZGRkNzktZGI2Ny00MjNjLWIxNzEtOTYyNmU1NTM0Zjk5IiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9',
      17: 'https://app.powerbi.com/view?r=eyJrIjoiZTM3MzBjMGEtYzc5Ni00ZGZjLWFhY2EtYzJkNjU0MDk5YTJjIiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9',
      19: 'https://app.powerbi.com/view?r=eyJrIjoiYjdkMTExNGEtMzE0MC00MjU1LTkxMjYtOTk3NzlkMGFlN2I4IiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9&pageName=ReportSection4e0642729b13a637881d',
      20: 'https://app.powerbi.com/view?r=eyJrIjoiOWMyZWQ2MDktNmU2Yy00OGQ3LWI2MjYtMDJlNTE2MDNiOTZiIiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9',
      21: 'https://app.powerbi.com/view?r=eyJrIjoiODY1NzJiMTctMTVlZC00ODMxLTk2MDQtYWZkMjdmNzQzYzBmIiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9',
      22: 'https://app.powerbi.com/view?r=eyJrIjoiM2MxNTJlNmYtNDk3OS00NDIxLTljMDMtNmZlMGJmNTcyZDk4IiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9',
      27: 'https://app.powerbi.com/reportEmbed?reportId=a25372f4-e008-4d71-85a1-20384953e908&autoAuth=true&ctid=ce6a7f5c-dd9a-4115-ae3a-a05a33587ae3',
      28: 'https://app.powerbi.com/view?r=eyJrIjoiYmMxNDljYzMtNDk5MS00Yzc4LTkwOTYtY2MxNDczODJiMTFhIiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9',
      29: 'https://app.powerbi.com/view?r=eyJrIjoiZTA3NDRlNGEtYjI0YS00ZWQwLTg2MmUtNWM3NDE1YTY0NzM2IiwidCI6ImNlNmE3ZjVjLWRkOWEtNDExNS1hZTNhLWEwNWEzMzU4N2FlMyJ9',
    }
    return companyDashboardLinks[companyId] ||  '';
  }

  render() {
    const { t } = this.props;
    const { isNewVersion, loading, reportId, reportToken } = this.state;
    
    const cookies = new Cookies();
    const  lang = cookies.get("lang") || 'es';
    
    if (loading) {
      return <div />
    }
    
    if (!isNewVersion) {
      const DASHBOARD_LINK = this.getDashboardLink();
      return (
        <div style={{ width: '100%', height: '100%' }}>
          <iframe
            width="100%"
            height={window.innerHeight}
            src={DASHBOARD_LINK}
            allowFullScreen={true}
            title="DASHBOARD"
            style={{ border: '0' }}
          ></iframe>{' '}
        </div>
      )
    }

    if (reportToken != null) {
      
      return (
        <div style={{ width: '100%', height: '100%' }}>
          <PowerBiComponent
            reportId={reportId}
            reportToken={reportToken}
            lang={lang}
          />
        </div>
      );
    }
    return (<div>{t('table.loading')}</div>)
  }
}

DashboardManagementControl.propTypes = {
  t: PropTypes.func,
}

export default withTranslation()(DashboardManagementControl);
