import React, { FC, useEffect, useState } from 'react';

import useStyles from './styles';

type Props = {
    isVisible: boolean;
    login: boolean;
}

const Chat: FC<Props> = ({ isVisible, login }) => {
    const [chatUrl, setChatUrl] = useState('');
    const classes = useStyles();

    const fetchChatUrl = async (): Promise<void> => {
        const requestOptions = {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json' }),
        };
        try {
            const response = await fetch('/chat-url', requestOptions);
            if (!response.ok) {
                console.error('Response error:', response.statusText);
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setChatUrl(data.url);
        } catch (error) {
            console.error('Error retrieving the chat URL:', error);
        }
    };

    useEffect(() => {
        fetchChatUrl();
    }, []);

    return (
        <div
            className={`${classes.chatContainer} ${isVisible ? classes.chatContainerVisible : classes.chatContainerHidden}`}
            id="chat-container"
        >
            <iframe
                width="100%"
                height="100%"
                src={chatUrl || 'about:blank'}
                allow="microphone" 
                id="chat-iframe"
                title="chat"
            ></iframe>
        </div>
    );
};

export default Chat;
