import { ApiErrorParser, CoreException, Either, VoidSuccess } from "../../../sdk";

import { ApiConfirmUserFromMapper } from "./api-confirm-user.from-mapper";
import { ApiGetTermsAndPolicyFromMap } from "./api-get-terms-and-policy.from-map";
import { ApiGetTermsAndPolicyFromMapper } from "./api-get-terms-and-policy.from-mapper";
import Cookies from "universal-cookie";
import PersonApiInvoker from "../../../api/PersonApiInvoker";
import { Profile } from "../domain/profile";
import { TermPolicy } from "../domain/term-policy";
import { UsersRepository } from "../application/repositories";
import { injectable } from "inversify";

const mapperTermPolicy = new ApiGetTermsAndPolicyFromMapper();
const mapperPassword = new ApiConfirmUserFromMapper();
const errorParser = new ApiErrorParser();
type LangKey = 'es' | 'en' | 'pt' | 'it' | 'fr';
const langMap = { "es": "es-ES", "en": "en-US", "pt": "pt-BR", "it": "it-IT", "fr": "fr-FR" };


@injectable()
export class ApiUsersRepository implements UsersRepository {

  async getProfile(): Promise<Either<CoreException, Profile>> {
    throw new Error("Method not implemented.");
  }

  getLangByCookies() {
    const cookies = new Cookies();
    const lang = cookies.get('lang') as LangKey | null;
    return lang ? langMap[lang] : langMap['es'];
  }

  async confirmUser(hash: string, companyId: string): Promise<Either<CoreException, string>> {
    const lang = this.getLangByCookies();
    const headers = new Headers({
      'Content-Type': 'application/json',
      'x-itlg-companyId': companyId,
      'Accept-Language': lang,
    });
    const response = await fetch(`/created/user/${hash}?language=${lang}`, { method: 'POST', headers });
    const data = await response.json();
    if (response.status !== 200) {
      const errMessage = (response.status === 400 && data?.error) || '';
      const err = errorParser.parse(response, errMessage);
      return Either.left(err);
    }
    return Either.right(mapperPassword.fromMap(data));
  }

  async getTermsAndPolicy(version: string): Promise<Either<CoreException, TermPolicy>> {
    return new Promise((resolve, _) => {
      let result: Either<CoreException, TermPolicy>;
      PersonApiInvoker.getTermsAndPolicy(version, (res: ApiGetTermsAndPolicyFromMap) => {
        const data = mapperTermPolicy.fromMap(res);
        result = Either.right(data);
        resolve(result);
      }, (error: CoreException) => {
        const err = errorParser.parse(error);
        result = Either.left(err);
        resolve(result);
      });
    });
  }

  async updateUserTerms(employeeId: number, version: string, isAccept: boolean): Promise<Either<CoreException, VoidSuccess>> {
    return new Promise((resolve, _) => {
      let result: Either<CoreException, TermPolicy>;
      PersonApiInvoker.updateUserTerms({ employeeId, version, isAccept }, (res: any) => {
        result = Either.right(res);
        resolve(result);
      }, (error: CoreException) => {
        const err = errorParser.parse(error);
        result = Either.left(err);
        resolve(result);
      });
    });
  }

}
