import '../HospitalizationStyles.css'

import { Add, Assignment, Delete } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'

import Card from '../../Card/Card.jsx'
import CardBody from '../../Card/CardBody.jsx'
import CardHeader from '../../Card/CardHeader.jsx'
import CardIcon from '../../Card/CardIcon.jsx'
import CheckInput from '../../CheckInput/index.jsx'
import CustomInput from '../../CustomInput/CustomInput'
import DateInput from '../../DateInput'
import GridContainer from '../../Grid/GridContainer'
import GridItem from '../../Grid/GridItem'
import HozpitalizationApiInvoker from '../../../api/HospitalizationApiInvoker'
import LocalHospitalIcon from '@material-ui/icons/LocalHospital'
import { Modal } from '@material-ui/core'
import PatologiesApiInvoker from '../../../api/RecordsApiInvoker'
import RegularButton from '../../CustomButtons/Button.jsx'
import SelectInput from '../../SelectInput/index.js'
import ValidationInput from '../../ValidationInput'
import { useMemo } from 'react'

const FormHospitalization = (props) => {
  const {
    setDataHospitalizationForm,
    validationShowDischargeForm,
    editDataHospitalization,
    setIsValidatedHospitalizationForm,
    editData,
    onlyView,
    save,
  } = props

  const [serviceCenter, setServiceCenter] = useState(null)
  const [treatingDoctors, setTreatingDoctors] = useState(null)
  const [treatingDoctorName, setTreatingDoctorName] = useState(null)
  const [treatingDoctorLastName, setTreatingDoctorLastName] = useState(null)
  const [dateAdmission, setDateAdmission] = useState(null)
  const [therapy, setTherapy] = useState(false)
  const [room, setRoom] = useState(
    editDataHospitalization ? editDataHospitalization.room : false
  )
  const [egressDate, setEgressDate] = useState(null)
  const [pathology, setPathology] = useState(null)
  const [category, setCategory] = useState(null)
  const [subcategory, setSubcategory] = useState(null)
  const [historyPathology, setHistoryPathology] = useState([])
  const [admissionPathology, setAdmissionPathology] = useState([])
  const [dischargePathology, setDischargePathology] = useState([])
  const [barthelIndex, setBarthelIndex] = useState(null)
  const [hospitalizationNewsList, setHospitalizationNewsList] = useState([])
  const [hospitalizationNews, setHospitalizationNews] = useState(null)
  const [dischargePlace, setDischargePlace] = useState(null)
  const [isValidated, setIsValidated] = useState(false)
  const [pathologyOptions, setPathologyOptions] = useState([])
  const [berthelIndexOptions, setBerthelIndexOptions] = useState([])
  const [servicesCenterOptions, setServicesCenterOptions] = useState([])
  const [treatingMedicalsOptions, setTreatingMedicalsOptions] = useState([])
  const [dischargePlaceOptions, setDischargePlaceOptions] = useState([])
  const [dressed, setDressed] = useState(null)
  const [bath, setBath] = useState(null)
  const [toilet, setToilet] = useState(null)
  const [mobility, setMobility] = useState(null)
  const [continence, setContinence] = useState(null)
  const [feeding, setFeeding] = useState(null)
  const [katzIndex, setKatzIndex] = useState(null)
  const [katzIndexId, setKatzIndexId] = useState(null)
  const [showInputTreatingMedicals, setShowInputTreatingMedicals] =
    useState(false)
  const [pathologyType, setPathologyType] = useState([])
  const [add, setAdd] = useState([])

  const [calculate, setCalculate] = useState(false)
  const [open, setOpen] = useState(false)
  const [openKatz, setOpenKatz] = useState(false)
  const [openAddNews, setOpenAddNews] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const handleOpenAddNews = () => setOpenAddNews(true)
  const handleCloseAddNews = () => setOpenAddNews(false)
  const handleOpenKatz = () => setOpenKatz(true)
  const handleCloseKatz = () => setOpenKatz(false)

  let hospitalizationLenth = hospitalizationNews
    ? hospitalizationNews.length
    : 0

  let data = {
    service_center: serviceCenter,
    therapy: therapy,
    room: room,
    date_admission: dateAdmission,
    egress_date: egressDate?.split(" ")[0],
    treating_doctors: treatingDoctors,
    history_of_pathology: historyPathology,
    admission_diagnosis: admissionPathology,
    discharge_diagnosis: dischargePathology,
    barthel_index: barthelIndex,
    hospitalizationNewsList: hospitalizationNews
      ? [{ hospitalizationDetail: hospitalizationNews }]
      : [],
    dischargePlace: dischargePlace,
    internmentKatz: {
      internmentKatzId: katzIndexId,
      bathing: bath === 2 ? 0 : 1,
      dress: dressed === 2 ? 0 : 1,
      usingTheToilet: toilet === 2 ? 0 : 1,
      mobility: mobility === 2 ? 0 : 1,
      continence: continence === 2 ? 0 : 1,
      feeding: feeding === 2 ? 0 : 1,
      indexKatz: katzIndex,
    },
  }

  const validateKatzIndex = () => {
    setCalculate(true)
    if (
      bath != null &&
      dressed != null &&
      toilet != null &&
      mobility != null &&
      continence != null &&
      feeding != null
    ) {
      setCalculate(false)
      let katzData = [
        { type: 'bath', point: bath === 2 ? 0 : 1 },
        { type: 'dressed', point: dressed === 2 ? 0 : 1 },
        { type: 'toilet', point: toilet === 2 ? 0 : 1 },
        { type: 'mobility', point: mobility === 2 ? 0 : 1 },
        { type: 'continence', point: continence === 2 ? 0 : 1 },
        { type: 'feeding', point: feeding === 2 ? 0 : 1 },
      ]
      let points = [
        { point: bath === 2 ? 0 : 1 },
        { point: dressed === 2 ? 0 : 1 },
        { point: toilet === 2 ? 0 : 1 },
        { point: mobility === 2 ? 0 : 1 },
        { point: continence === 2 ? 0 : 1 },
        { point: feeding === 2 ? 0 : 1 },
      ]
      validateResultKatzIndex(katzData, points)
    }
  }

  const validateResultKatzIndex = (data, points) => {
    let totalPoints = 0
    points.forEach((point) => {
      totalPoints += point.point
    })

    switch (totalPoints) {
      case 6:
        setKatzIndex('Grado A. Ausencia de incapacidad (0 puntos)')
        break
      case 5:
        setKatzIndex('Grado B. Incapacidad leve (1 punto)')
        break
      case 4:
        if (data[0].point === 0) {
          setKatzIndex('Grado C. Incapacidad moderada (2 puntos)')
        } else {
          setKatzIndex(
            'Grado H. Incapacidad moderada a severa. No calificable como C, D, E ó F. (2 a 6 puntos)'
          )
        }
        break
      case 3:
        if (data[0].point === 0 && data[1].point === 0) {
          setKatzIndex('Grado D. Incapacidad moderada (3 puntos)')
        } else {
          setKatzIndex(
            'Grado H. Incapacidad moderada a severa. No calificable como C, D, E ó F. (2 a 6 puntos)'
          )
        }
        break
      case 2:
        if (data[0].point === 0 && data[1].point === 0 && data[2].point === 0) {
          setKatzIndex('Grado E. Incapacidad severa (4 puntos)')
        } else {
          setKatzIndex(
            'Grado H. Incapacidad moderada a severa. No calificable como C, D, E ó F. (2 a 6 puntos)'
          )
        }
        break
      case 1:
        if (
          data[0].point === 0 &&
          data[1].point === 0 &&
          data[2].point === 0 &&
          data[3].point === 0
        ) {
          setKatzIndex('Grado F. Incapacidad severa (5 puntos)')
        } else {
          setKatzIndex(
            'Grado H. Incapacidad moderada a severa. No calificable como C, D, E ó F. (2 a 6 puntos)'
          )
        }
        break
      case 0:
        setKatzIndex('Grado G. Incapacidad severa (6 puntos)')
        break
      default:
        break
    }
    handleCloseKatz()
  }

  const formatDate = (dateData) => {
    let date = new Date(dateData)

    let day = date.getDate()
    let month = date.getMonth() + 1
    let year = date.getFullYear()
    let hours = date.getHours()
    let minutes = date.getMinutes()
    let seconds = date.getSeconds()

    if (month < 10) {
      return `${day}-0${month}-${year} / ${hours}:${minutes}:${seconds}`
    } else {
      return `${day}-${month}-${year} / ${hours}:${minutes}:${seconds}`
    }
  }

  const formatActualDate = (dateData) => {
    let date = new Date(dateData)

    let day = date.getDate()
    let month = date.getMonth() + 1
    let year = date.getFullYear()

    if (month < 10) {
      month = `0${month}`
    }
    if (day < 10) {
      day = `0${day}`
    }

    return `${year}-${month}-${day}`
  }

  let actualDate = new Date()
  actualDate = formatActualDate(actualDate)

  const resetValues = () => {
    setPathology(null)
    setCategory(null)
    setSubcategory(null)
    setPathologyType(null)
  }

  const addPathology = () => {
    handleClose()
    let data = {
      pathologyId: {
        recordId: pathology,
        name: category,
        parent: { name: subcategory },
      },
    }
    switch (pathologyType) {
      case 'historyPathology':
        setHistoryPathology([...historyPathology, data])
        resetValues()
        break

      case 'admissionPathology':
        setAdmissionPathology([...admissionPathology, data])
        resetValues()
        break

      case 'dischargePathology':
        setDischargePathology([...dischargePathology, data])
        resetValues()
        break

      default:
        break
    }
  }

  const deletePathology = (pathologyId, type) => {
    switch (type) {
      case 'historyPathology':
        const newPathologiesH = historyPathology.filter(
          (pathology) => pathology.pathologyId.recordId !== pathologyId
        )
        setHistoryPathology(newPathologiesH)
        resetValues()
        break

      case 'admissionPathology':
        const newPathologiesA = admissionPathology.filter(
          (pathology) => pathology.pathologyId.recordId !== pathologyId
        )
        setAdmissionPathology(newPathologiesA)
        resetValues()
        break

      case 'dischargePathology':
        const newPathologiesD = dischargePathology.filter(
          (pathology) => pathology.pathologyId.recordId !== pathologyId
        )
        setDischargePathology(newPathologiesD)
        resetValues()
        break

      default:
        break
    }
  }

  const setDataEdit = (data) => {
    setServiceCenter(data.serviceCenter)
    setTreatingDoctors(data.treatingMedicalsId)
    setDateAdmission(data.dateAdmission)
    setTherapy(data.therapy)
    setRoom(data.room)
    setEgressDate(data.egressDate)
    setHistoryPathology(data.historyPathology ? data.historyPathology : [])
    setAdmissionPathology(
      data.admissionPathology ? data.admissionPathology : []
    )
    setDischargePathology(
      data.dischargePathology ? data.dischargePathology : []
    )
    setDressed(
      data.internmentKatz && data.internmentKatz.dress === 0
        ? 2
        : data.internmentKatz && data.internmentKatz.dress === 1
        ? 1
        : null
    )
    setBath(
      data.internmentKatz && data.internmentKatz.bathing === 0
        ? 2
        : data.internmentKatz && data.internmentKatz.bathing === 1
        ? 1
        : null
    )
    setToilet(
      data.internmentKatz && data.internmentKatz.usingTheToilet === 0
        ? 2
        : data.internmentKatz && data.internmentKatz.usingTheToilet === 1
        ? 1
        : null
    )
    setMobility(
      data.internmentKatz && data.internmentKatz.mobility === 0
        ? 2
        : data.internmentKatz && data.internmentKatz.mobility === 1
        ? 1
        : null
    )
    setContinence(
      data.internmentKatz && data.internmentKatz.continence === 0
        ? 2
        : data.internmentKatz && data.internmentKatz.continence === 1
        ? 1
        : null
    )
    setFeeding(
      data.internmentKatz && data.internmentKatz.feeding === 0
        ? 2
        : data.internmentKatz && data.internmentKatz.feeding === 1
        ? 1
        : null
    )
    setKatzIndex(data.internmentKatz && data.internmentKatz.indexKatz)
    setBarthelIndex(data.barthelIndexId)
    setKatzIndexId(data.internmentKatz && data.internmentKatz.internmentKatzId)
    setHospitalizationNewsList(data.hospitalizationNewsList)
    setDischargePlace(data.dischargePlaceId)
  }

  const formatPathologies = (patologies) =>
    patologies.map((e) => ({
      id: e.id,
      value: e.name,
    }))

  const formatBerthelIndex = (index) =>
    index.map((e) => ({
      id: e.id,
      value: e.name,
    }))

  const formatServicesCenter = (index) =>
    index.map((e) => ({
      id: e.axaServicesCenterId,
      value: e.name,
    }))

  const formatTreatingMedicals = (index) =>
    index.map((e) => ({
      id: e.treatingMedicalsId,
      value: `${e.name} ${e.lastName}`,
    }))

  const formatDischargePlace = (index) =>
    index.map((e) => ({
      id: e.dischargePlaceId,
      value: e.name,
    }))

  const SetPathologiesData = () => {
    PatologiesApiInvoker.getAllPatologies((data) => {
      let pathologies = formatPathologies(data)
      setPathologyOptions(pathologies)
    })
  }

  const SetBerthelIndexData = () => {
    PatologiesApiInvoker.getAllBarthel((data) => {
      let berthelIndex = formatBerthelIndex(data)
      setBerthelIndexOptions(berthelIndex)
    })
  }

  const SetServicesCenterData = () => {
    HozpitalizationApiInvoker.getServicesCenter((data) => {
      let servicesCenter = formatServicesCenter(data)
      setServicesCenterOptions(servicesCenter)
    })
  }

  const SetTreatingMedicalsData = () => {
    HozpitalizationApiInvoker.getTreatingMedicals((data) => {
      let servicesCenter = formatTreatingMedicals(data)
      servicesCenter.unshift({
        id: 'otro',
        value: 'Otro... (Agregar)',
      })
      setTreatingMedicalsOptions(servicesCenter)
    })
  }

  const SetDischargePlace = () => {
    HozpitalizationApiInvoker.getDischargePlace((data) => {
      let dischargePlace = formatDischargePlace(data)
      setDischargePlaceOptions(dischargePlace)
    })
  }

  const validateForm = () => {
    if (
      serviceCenter &&
      dateAdmission &&
      treatingDoctors &&
      historyPathology.length > 0 &&
      admissionPathology.length > 0
    ) {
      setIsValidated(true)
      setIsValidatedHospitalizationForm(true)
    } else {
      setIsValidated(false)
      setIsValidatedHospitalizationForm(false)
    }
  }

  const validateShowForm = () => {
    if (
      dischargePlace &&
      dischargePlace !== null &&
      dischargePlace !== '' &&
      egressDate !== null &&
      egressDate !== ''
    ) {
      validationShowDischargeForm(true)
    } else {
      validationShowDischargeForm(false)
    }
  }

  const addTreatingMedicals = (value) => {
    if (value === 'otro') {
      setShowInputTreatingMedicals(true)
      setTreatingDoctors(null)
    } else {
      setTreatingDoctors(value)
    }
  }

  const addHospitalizationNews = (data) => {
    handleCloseAddNews()
    let hospitalizationDetail = {
      hospitalizationDetail: data,
    }
    setHospitalizationNewsList([
      ...hospitalizationNewsList,
      hospitalizationDetail,
    ])
  }

  const addTreatingDoctor = () => {
    let data = {
      name: treatingDoctorName,
      lastName: treatingDoctorLastName,
    }
    HozpitalizationApiInvoker.addTreatingMedicals(data, (response) => {
      SetTreatingMedicalsData()
      setShowInputTreatingMedicals(false)
    })
  }

  useEffect(() => {
    SetTreatingMedicalsData()
    SetServicesCenterData()
    SetPathologiesData()
    SetBerthelIndexData()
    SetDischargePlace()
    setDateAdmission(actualDate)
    if (editData) {
      setDataEdit(editData)
    }
  }, [editData])

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    validateForm()
    validateShowForm()
    setDataHospitalizationForm(data)
    setIsValidatedHospitalizationForm(isValidated)
  }, [
    editDataHospitalization,
    serviceCenter,
    dateAdmission,
    treatingDoctorName,
    therapy,
    room,
    treatingDoctors,
    treatingDoctorLastName,
    egressDate,
    historyPathology,
    admissionPathology,
    dischargePathology,
    barthelIndex,
    hospitalizationNews,
    hospitalizationNewsList,
    dischargePlace,
    isValidated,
    showInputTreatingMedicals,
    save,
    bath,
    dressed,
    toilet,
    mobility,
    continence,
    feeding,
    calculate,
    katzIndex,
  ])

  return (
    <Card>
      <CardHeader color="warning" icon>
        <CardIcon color="warning">
          <LocalHospitalIcon />
        </CardIcon>
        <h4>Datos de hospitalización</h4>
      </CardHeader>
      <CardBody>
        <GridContainer>
          <GridItem md={8}>
            <SelectInput
              label="Centro de servicio *"
              onSelectedValue={(value) => setServiceCenter(value)}
              elements={servicesCenterOptions}
              value={serviceCenter}
              invalid={!serviceCenter && save}
              disabled={onlyView}
            ></SelectInput>
          </GridItem>
          <GridItem md={4}>
            <div className="chechbox-container">
              <p className="label-chechbox-container">Ubicaciones:</p>
              <CheckInput
                label="Terapia intensiva"
                labelPlacement="end"
                onChangeValue={() => setTherapy(!therapy)}
                checked={therapy}
               // disabled={onlyView}
                inputProps={{ disabled: onlyView }}
              ></CheckInput>
              <CheckInput
                onChangeValue={() => setRoom(!room)}
                label="Sala"
                labelPlacement="end"
                checked={room}
               // disabled={onlyView}
                inputProps={{ disabled: onlyView }}
              ></CheckInput>
            </div>
          </GridItem>
          <GridItem md={3}>
            <DateInput
              text="Fecha de ingreso *"
              onChangeValue={(e) => setDateAdmission(e)}
              value={dateAdmission}
              isRequired={true}
              inputProps={{ disabled: onlyView }}
            />
          </GridItem>
          <GridItem md={3}>
            <DateInput
              text="Fecha de egreso"
              minDate={true}
              min={dateAdmission}
              onChangeValue={(e) => setEgressDate(e)}
              value={egressDate}
              disabled={!dateAdmission || onlyView}
            />
          </GridItem>
          {!showInputTreatingMedicals ? (
            <GridItem md={6}>
              <SelectInput
                label="Médicos tratantes *"
                onSelectedValue={(value) => addTreatingMedicals(value)}
                elements={treatingMedicalsOptions}
                value={treatingDoctors}
                invalid={!treatingDoctors && save}
                disabled={onlyView}
              ></SelectInput>
            </GridItem>
          ) : (
            <>
              <GridItem md={2}>
                <CustomInput
                  value={treatingDoctorName}
                  labelText="Nombre *"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: 'text',
                  }}
                  onChange={(e) => setTreatingDoctorName(e.target.value)}
                />
              </GridItem>
              <GridItem md={2}>
                <CustomInput
                  value={treatingDoctorLastName}
                  labelText="Apellido *"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: 'text',
                  }}
                  onChange={(e) => setTreatingDoctorLastName(e.target.value)}
                />
              </GridItem>
              <div className="delete-medication-button">
                <GridItem md={2}>
                  <div className="button-add-option">
                    <RegularButton
                      className="delete-medication-button"
                      onClick={addTreatingDoctor}
                      disabled={!treatingDoctorName || !treatingDoctorLastName}
                      color="primary"
                      size="sm"
                    >
                      Agregar
                    </RegularButton>
                    <RegularButton
                      className="delete-medication-button"
                      onClick={() => setShowInputTreatingMedicals(false)}
                      color="danger"
                      size="sm"
                    >
                      X
                    </RegularButton>
                  </div>
                </GridItem>
              </div>
            </>
          )}
          <Modal
            open={openAddNews}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <>
              <div className="modal-medication">
                <Card>
                  <CardHeader color="primary" icon>
                    <GridItem md={12}>
                      <CardIcon color="warning">
                        <Assignment />
                      </CardIcon>
                      <h4>Novedades de la hospitalización</h4>
                    </GridItem>
                  </CardHeader>
                  <CardBody>
                    <form action="">
                      <GridContainer>
                        <GridItem md={12}>
                          <ValidationInput
                            text="Nueva novedad de la hospitalización"
                            multiline={true}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: 'text',
                              maxlength: '2500',
                            }}
                            errorText={`Hasta 2500 caracteres. Te quedan ${
                              2500 - hospitalizationLenth
                            } `}
                            onChangeValue={(value) =>
                              setHospitalizationNews(value)
                            }
                          />
                        </GridItem>

                        <GridItem xs={12}>
                          <p className="required-text">
                            <small>*</small> {'Campos requeridos'}
                          </p>
                        </GridItem>

                        <GridItem md={12}>
                          <div className="modal-footer">
                            <RegularButton
                              onClick={handleCloseAddNews}
                              size="sm"
                              color="default"
                            >
                              Cancelar
                            </RegularButton>
                            <RegularButton
                              onClick={() =>
                                addHospitalizationNews(hospitalizationNews)
                              }
                              size="sm"
                              color="primary"
                              disabled={!hospitalizationNews}
                            >
                              Agregar
                            </RegularButton>
                          </div>
                        </GridItem>
                      </GridContainer>
                    </form>
                  </CardBody>
                </Card>
              </div>
            </>
          </Modal>
          <Modal
            open={open}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <>
              <div className="modal-medication">
                <Card>
                  <CardHeader color="primary" icon>
                    <GridItem md={12}>
                      <CardIcon color="rose">
                        <Assignment />
                      </CardIcon>
                      <h4>
                        {pathologyType === 'historyPathology'
                          ? 'Patología - antecedentes patológicos personales'
                          : pathologyType === 'admissionPathology'
                          ? 'Patología - diagnóstico de ingreso'
                          : pathologyType === 'dischargePathology'
                          ? 'Patología - diagnóstico de egreso'
                          : ''}
                      </h4>
                    </GridItem>
                  </CardHeader>
                  <CardBody>
                    <form action="">
                      <GridContainer>
                        <GridItem md={4}>
                          <SelectInput
                            label="Patología *"
                            onSelectedValue={(value) => {
                              PatologiesApiInvoker.getPatologyDetails(
                                value,
                                (response) => {
                                  setCategory(response.parent.name)
                                  setSubcategory(response.parent.parent.name)
                                }
                              )
                              setPathology(value)
                            }}
                            elements={pathologyOptions}
                            value={pathology}
                          ></SelectInput>
                        </GridItem>
                        <GridItem md={4}>
                          <ValidationInput
                            value={category ? category : ''}
                            text="Categoría:"
                            id="category"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: 'text',
                            }}
                            disabled={true}
                          />
                        </GridItem>
                        <GridItem md={4}>
                          <ValidationInput
                            value={subcategory ? subcategory : ''}
                            text="Subcategoría:"
                            id="subcategory"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: 'text',
                            }}
                            disabled={true}
                          />
                        </GridItem>
                        <GridItem xs={12}>
                          <p className="required-text">
                            <small>*</small> {'Campos requeridos'}
                          </p>
                        </GridItem>

                        <GridItem md={12}>
                          <div className="modal-footer">
                            <RegularButton
                              onClick={handleClose}
                              size="sm"
                              color="default"
                            >
                              Cancelar
                            </RegularButton>
                            <RegularButton
                              onClick={() =>
                                addPathology()
                              }
                              size="sm"
                              color="primary"
                              disabled={!pathology}
                            >
                              Agregar
                            </RegularButton>
                          </div>
                        </GridItem>
                      </GridContainer>
                    </form>
                  </CardBody>
                </Card>
              </div>
            </>
          </Modal>
          <GridItem md={12}>
            <div className="title-pathologies-section">
              <h4>Antecedentes patológicos personales *</h4>
            </div>
          </GridItem>
          {historyPathology.length ? (
            <>
              {historyPathology.map((pathology) => (
                <>
                  <GridItem md={4}>
                    <SelectInput
                      label="Patología"
                      elements={pathologyOptions}
                      value={pathology.pathologyId.recordId}
                      disabled={true}
                    ></SelectInput>
                  </GridItem>
                  <GridItem md={3}>
                    <ValidationInput
                      value={pathology.pathologyId.name}
                      text="Categoría:"
                      id="category"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: 'text',
                      }}
                      disabled={true}
                    />
                  </GridItem>
                  <GridItem md={3}>
                    <ValidationInput
                      value={pathology.pathologyId.parent.name}
                      text="Subcategoría:"
                      id="subcategory"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: 'text',
                      }}
                      disabled={true}
                    />
                  </GridItem>
                  <GridItem md={2}>
                    <div className="delete-medication-button">
                      {!onlyView && (
                        <RegularButton
                          onClick={() => {
                            deletePathology(
                              pathology.pathologyId.recordId,
                              'historyPathology'
                            )
                          }}
                          size="sm"
                          color="danger"
                        >
                          <Delete />
                        </RegularButton>
                      )}
                    </div>
                  </GridItem>
                </>
              ))}
            </>
          ) : (
            <>
              {historyPathology.length === 0 && save ? (
                <p className="no-pathology-message error-text">
                  Los antecedentes patológicos personales son requeridos
                </p>
              ) : (
                <p className="no-pathology-message">
                  No hay patologías cargadas aún
                </p>
              )}
            </>
          )}
          <GridItem md={12}>
            {historyPathology.length < 5 && !onlyView && (
              <div className="add-pathology-button">
                <RegularButton
                  onClick={() => {
                    handleOpen()
                    setPathologyType('historyPathology')
                  }}
                  size="sm"
                  color="warning"
                >
                  <Add className="appointments-button-icon" /> Agregar Patología
                </RegularButton>
              </div>
            )}
          </GridItem>
          <hr className="separator" />
          <GridItem md={12}>
            <div className="title-pathologies-section">
              <h4>Diagnóstico de ingreso *</h4>
            </div>
          </GridItem>
          {admissionPathology && admissionPathology.length ? (
            <>
              {admissionPathology.map((pathology) => (
                <>
                  <GridItem md={4}>
                    <SelectInput
                      label="Patología"
                      elements={pathologyOptions}
                      value={pathology.pathologyId.recordId}
                      disabled={true}
                    ></SelectInput>
                  </GridItem>
                  <GridItem md={3}>
                    <ValidationInput
                      value={pathology.pathologyId.parent.name}
                      text="Categoría:"
                      id="category"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: 'text',
                      }}
                      disabled={true}
                    />
                  </GridItem>
                  <GridItem md={3}>
                    <ValidationInput
                      value={pathology.pathologyId.parent.name}
                      text="Subcategoría:"
                      id="subcategory"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: 'text',
                      }}
                      disabled={true}
                    />
                  </GridItem>
                  <GridItem md={2}>
                    <div className="delete-medication-button">
                      {!onlyView && (
                        <RegularButton
                          onClick={() => {
                            deletePathology(
                              pathology.pathologyId.recordId,
                              'admissionPathology'
                            )
                          }}
                          size="sm"
                          color="danger"
                        >
                          <Delete />
                        </RegularButton >
                      )}
                    </div>
                  </GridItem>
                </>
              ))}
            </>
          ) : (
            <>
              {admissionPathology.length === 0 && save ? (
                <p className="no-pathology-message error-text">
                  Los diagnósticos de ingreso son requeridos
                </p>
              ) : (
                <p className="no-pathology-message">
                  No hay patologías cargadas aún
                </p>
              )}
            </>
          )}
          <GridItem md={12}>
            {admissionPathology &&
              admissionPathology.length < 5 &&
              !onlyView && (
                <div className="add-pathology-button">
                  <RegularButton
                    onClick={() => {
                      handleOpen()
                      setPathologyType('admissionPathology')
                    }}
                    size="sm"
                    color="warning"
                  >
                    <Add className="appointments-button-icon" /> Agregar
                    Patología
                  </RegularButton>
                </div>
              )}
          </GridItem>
          <hr className="separator" />
          <GridItem md={12}>
            <div className="title-pathologies-section">
              <h4>Diagnóstico de egreso</h4>
            </div>
          </GridItem>
          {dischargePathology && dischargePathology.length ? (
            <>
              {dischargePathology.map((pathology) => (
                <>
                  <GridItem md={4}>
                    <SelectInput
                      label="Patología"
                      elements={pathologyOptions}
                      value={pathology.pathologyId.recordId}
                      disabled={true}
                    ></SelectInput>
                  </GridItem>
                  <GridItem md={3}>
                    <ValidationInput
                      value={pathology.pathologyId.parent.name}
                      text="Categoría:"
                      id="category"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: 'text',
                      }}
                      disabled={true}
                    />
                  </GridItem>
                  <GridItem md={3}>
                    <ValidationInput
                      value={pathology.pathologyId.parent.name}
                      text="Subcategoría:"
                      id="subcategory"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: 'text',
                      }}
                      disabled={true}
                    />
                  </GridItem>
                  <GridItem md={2}>
                    <div className="delete-medication-button">
                      {!onlyView && (
                        <RegularButton
                          onClick={() => {
                            deletePathology(
                              pathology.pathologyId.recordId,
                              'dischargePathology'
                            )
                          }}
                          size="sm"
                          color="danger"
                        >
                          <Delete />
                        </RegularButton>
                      )}
                    </div>
                  </GridItem>
                </>
              ))}
            </>
          ) : (
            <p className="no-pathology-message">
              No hay patologías cargadas aún
            </p>
          )}
          <GridItem md={12}>
            {dischargePathology &&
              dischargePathology.length < 5 &&
              !onlyView && (
                <div className="add-pathology-button">
                  <RegularButton
                    onClick={() => {
                      handleOpen()
                      setPathologyType('dischargePathology')
                    }}
                    size="sm"
                    color="warning"
                    disabled={!egressDate}
                  >
                    <Add className="appointments-button-icon" /> Agregar
                    Patología
                  </RegularButton>
                </div>
              )}
          </GridItem>
          <hr className="separator" />
          <GridItem md={12}>
            <div className="title-pathologies-section">
              <h4>Novedades de la hospitalización</h4>
            </div>
          </GridItem>
          <GridItem md={12}>
            {hospitalizationNewsList?.length ? (
              <>
                {hospitalizationNewsList?.map((news) => (
                  <div className="hospitalization-detail">
                    <p className="hospitalization-detail-text">
                      {news.hospitalizationDetail}
                    </p>
                    <p className="hospitalization-detail-date">
                      <em>
                        {news.createdAt ? (
                          <p>
                            {`${formatDate(news.createdAt)} ${
                              news.userName ? `- ${news.userName}` : ''
                            }`}
                          </p>
                        ) : (
                          'Nueva novedad'
                        )}
                      </em>
                    </p>
                  </div>
                ))}
              </>
            ) : (
              <p className="hospitalization-detail-message">
                No hay novedades cargadas aún
              </p>
            )}
          </GridItem>
          <GridItem md={12}>
            <div className="add-pathology-button">
              <RegularButton
                onClick={() => {
                  handleOpenAddNews()
                  setPathologyType('dischargePathology')
                }}
                size="sm"
                color="warning"
                disabled={hospitalizationNews}
              >
                <Add className="appointments-button-icon" /> Agregar Novedad
              </RegularButton>
            </div>
          </GridItem>
          <GridItem md={4}>
            <SelectInput
              label="Destino"
              onSelectedValue={(value) => setDischargePlace(parseInt(value))}
              elements={dischargePlaceOptions}
              value={dischargePlace ? dischargePlace : ''}
              disabled={!egressDate || onlyView}
            ></SelectInput>
          </GridItem>
          <GridItem xs={12}>
            <p className="required-text">
              <small>*</small> {'Campos requeridos'}
            </p>
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  )
}

export default FormHospitalization
